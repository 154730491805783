import React from 'react'

export default function Footer() {
  return (
    <div>
          <footer>
        <div class="widget_wrap overlay">
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <div class="widget_list">
                  <h4 class="widget_title">Find Us</h4>
                  <div class="widget_text">
                    <ul>
                      <li><a href="#">2307 Beverley Rd</a></li>
                      <li><a href="#">Brooklyn, New York 11226</a></li>
                      <li><a href="#">United States.</a></li>
                    </ul>
                    <p>
                      Open Monday to Saturday From 7h to 18h or talk to an
                      expert 0712-0610-3314 – available 24/7
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <div class="widget_list">
                      <h4 class="widget_title">Explore</h4>
                      <div class="widget_service">
                        <ul>
                          <li><a href="#">Online Services</a></li>
                          <li><a href="#">Residents</a></li>
                          <li><a href="#">Business</a></li>
                          <li><a href="#">Government</a></li>
                          <li><a href="#">Visiting</a></li>
                          <li><a href="#">Employment</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="widget_list">
                      <h4 class="widget_title">Features</h4>
                      <div class="widget_service">
                        <ul>
                          <li><a href="#">Online Services</a></li>
                          <li><a href="#">Residents</a></li>
                          <li><a href="#">Business</a></li>
                          <li><a href="#">Government</a></li>
                          <li><a href="#">Visiting</a></li>
                          <li><a href="#">Employment</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="widget_list">
                      <h4 class="widget_title">Services</h4>
                      <div class="widget_service">
                        <ul>
                          <li><a href="#">Online Services</a></li>
                          <li><a href="#">Residents</a></li>
                          <li><a href="#">Business</a></li>
                          <li><a href="#">Government</a></li>
                          <li><a href="#">Visiting</a></li>
                          <li><a href="#">Employment</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="widget_list">
                  <h4 class="widget_title">For Appointment</h4>
                  <div class="widget_text text2">
                    <ul>
                      <li>
                        <a href="#">Monday<span>08 am - 3 pm</span></a>
                      </li>
                      <li>
                        <a href="#">Tusday <span>08 am - 3 pm</span></a>
                      </li>
                      <li>
                        <a href="#">Wednesfay<span>08 am - 3 pm</span></a>
                      </li>
                      <li>
                        <a href="#">Thursday<span>08 am - 3 pm</span></a>
                      </li>
                      <li>
                        <a href="#">FFriday <span>08 am - 3 pm</span></a>
                      </li>
                      <li>
                        <a href="#">Saturday - Sunday<span>Closed</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="widget_copyright">
                <div class="col-md-3 col-sm-6">
                  <div class="widget_logo">
                    <a href="#"><img src="images/widget-logo.png" alt="" /></a>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="copyright_text">
                    <p>
                      <span
                        >Copyright © 2018 - 19 The Local City Municipal.</span
                      >
                      Designed and Developed by kodeforest
                    </p>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="city_top_social">
                    <ul>
                      <li>
                        <a href="#"><i class="fa fa-facebook"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="fa fa-twitter"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="fa fa-linkedin"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="fa fa-youtube"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="fa fa-google"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
