import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';
import useFetch from '../ApiClient/GetApi';
import { IMAGE_URL } from '../Utils/urls';
import DOMPurify from 'dompurify';

export default function News() {
    const location = useLocation();
    const { apiData } = useFetch(`getNews`);
    console.log(apiData?.data?.newsData);

    // Function to limit description to 100 words
    const limitDescription = (html) => {
        const plainText = DOMPurify.sanitize(html).replace(/<[^>]+>/g, ' '); // Strip HTML tags
        const words = plainText.split(/\s+/).slice(0, 20); // Get first 100 words
        return words.join(' ') + (words.length === 20 ? '...' : ''); // Add ellipsis if truncated
    };

    return (
        <div>
            <Header />
            <div>
                <div className="sab_banner overlay">
                    <div className="container">
                        <div className="sab_banner_text">
                            <h2>Latest News</h2>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">News</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="city_services2_wrap">
                    <div className="container">
                        <div className="row">
                           {
                            apiData?.data?.newsData?.map((news) => (
                                <div className="col-md-4 col-sm-6" key={news.id}>
                                    <div className="city_service2_fig">
                                        <figure className="overlay" style={{height:"150px"}}>
                                            <img src={IMAGE_URL + news.image} alt={news.title} />
                                            <div className="city_service2_list">
                                                <div className="city_service2_caption">
                                                    <h4>{news.title}</h4>
                                                </div>
                                            </div>
                                        </figure>
                                        <div className="city_service2_text">
                                            <p>
                                                <div 
                                                    className="news-description" 
                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(limitDescription(news.description)) }} 
                                                />
                                            </p>
                                            <a className="see_more_btn" href={`/news/${news.id}`}>
                                                See More <i className="fa icon-next-1"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))
                           }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}