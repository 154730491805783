import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { red } from '../Utils/colors'

export default function ContactUs() {
    return (
        <div>
            <Header />
            <div className='container'>
                <div class="city_event_detail contact" style={{border:"1px solid red",margin:"10px",padding:"10px",background:red}}>
                    <div class="section_heading center">
                        <h2 style={{color:"white"}}>Contact With Us</h2>
                    </div>
                    <div class="event_booking_form">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="event_booking_field">
                                    <input type="text" placeholder="Name" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="event_booking_field">
                                    <input type="text" placeholder="Email" />
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="event_booking_field">
                                    <input type="text" placeholder="Subject" />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="event_booking_area">
                                    <textarea>Enter Your Message Here</textarea>
                                </div>
                                <a class="theam_btn btn2" href="#">Submit</a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}
