import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useLocation } from 'react-router-dom'
import useFetch from '../ApiClient/GetApi';
import { formatDate } from '../Utils/DateFormat';

export default function PollingStationDetails() {
    const location = useLocation();
    const { apiData } = useFetch(`pollingStationDetails/${location?.state?.id}`);
    console.log(apiData)
    return (
        <div>
            <Header />
            <div class="sab_banner overlay">
                <div class="container">
                    <div class="sab_banner_text">
                        <h2>{apiData?.data?.station?.name}</h2>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a >Code</a></li>
                            <li class="breadcrumb-item active">{apiData?.data?.station?.code}</li>
                            <li class="breadcrumb-item"><a >Consistuency</a></li>
                            <li class="breadcrumb-item active">{apiData?.data?.station?.constituency?.name}</li>
                            <li class="breadcrumb-item"><a >District</a></li>
                            <li class="breadcrumb-item active">{apiData?.data?.station?.constituency?.district?.name}</li>
                            <li class="breadcrumb-item"><a >Region</a></li>
                            <li class="breadcrumb-item active">{apiData?.data?.station?.constituency?.district?.region?.name}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="city_blog2_wrap">
                <div class="container">
                    <div class="forum_list">
                        <table style={{ border: "3px solid white" }} class="table table-striped table-hover table-bordered shadow-sm">
                            <thead class="table-dark text-center">
                                <tr>
                                    <th scope="col">Candidate Name</th>
                                    <th scope="col">Party</th>
                                    <th scope="col">Vote Secured (Number)</th>
                                    <th scope="col">Vote Secured (Text)</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                {
                                    apiData?.data?.station?.candidatepollingstations?.map((poll) => (
                                        <tr>
                                            <td>{poll?.candidate?.name}</td>
                                            <td>{poll?.candidate?.party?.name}</td>
                                            <td>{poll?.voteSecured ? poll?.voteSecured : "0"}</td>
                                            <td>{poll?.inwords ? poll?.inwords : "Zero"}</td>
                                            <td>{formatDate(poll.updatedAt)}</td>

                                        </tr>
                                    ))
                                }


                            </tbody>
                        </table>



                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
