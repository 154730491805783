import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import useFetch from '../ApiClient/GetApi';

export default function AllCandidates() {
    const location = useLocation();
    const navigate = useNavigate();
    const { apiData } = useFetch(`allCandidates`);

    const candidateDetails = (obj) => {
        navigate("/candidate-details", { state: { id: obj.id } })
    }
    return (
        <div>
            <Header />
            <div>
                <div className="city_blog2_wrap team">
                    <div className="container">
                        <div className="row">
                            {
                                apiData?.data?.candidates.length === 0 ? "No Candidate" :
                                    apiData?.data?.candidates?.map((candidate) => (
                                        <div onClick={() => candidateDetails(candidate)} className="col-md-3 col-sm-6" style={{ margin: "10px 0px" }} key={candidate.id}>
                                            <div className="city_team_fig">
                                                <figure className="overlay">
                                                    <img src="images/candidate.png" alt="Candidate" />
                                                    <div className="city_top_social">
                                                        <ul>
                                                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-youtube"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </figure>
                                                <div className="city_team_text">
                                                    <h4><a className="text-capitalize" href="#">{candidate?.name}</a></h4>
                                                    <p>Candidate Type : {candidate?.candidatetype?.name}</p>
                                                    {candidate?.candidatetype?.name === "Presidential" ? <p>No Constituency</p> : <p>Constituency : {candidate?.candidateconsistuency?.constituency?.name}</p>}
                                                    <p>Party : <strong>{candidate?.party?.name}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
