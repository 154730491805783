import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useLocation } from 'react-router-dom'
import useFetch from '../ApiClient/GetApi';
import { formatDate } from '../Utils/DateFormat';

export default function
    () {

    const location = useLocation();
    console.log(location?.state);
    const { apiData } = useFetch(`candidateDetails/${location?.state?.id}`);
    console.log(apiData)


    return (
        <div>
            <Header />
            <div class="sab_banner overlay">
                <div class="container">
                    <div class="sab_banner_text">
                        <h2>{apiData?.data?.candidateData?.name}</h2>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Consistuency</a></li>

                            <li class="breadcrumb-item active">{apiData?.data?.candidateData?.candidateconsistuency?.constituency?.name}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="city_blog2_wrap">
                <div class="container">
                    <div class="forum_list">
                        <table class="table table-striped table-hover table-bordered shadow-sm">
                            <thead class="table-dark text-center">
                                <tr>
                                    <th scope="col">Polling Station Name</th>
                                    <th scope="col">Polling Station Code</th>
                                    <th scope="col">Vote Secured (Number)</th>
                                    <th scope="col">Vote Secured (Text)</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                {
                                    apiData?.data?.candidateData?.candidatepollingstations?.map((poll) => (
                                        <tr>
                                            <td>{poll?.pollingstation?.name}</td>
                                            <td>{poll?.pollingstation?.code}</td>
                                            <td>{poll?.voteSecured}</td>
                                            <td>{poll?.inwords}</td>
                                            <td>{formatDate(poll.updatedAt)}</td>

                                        </tr>
                                    ))
                                }


                            </tbody>
                        </table>



                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
