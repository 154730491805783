import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import useFetch from "../ApiClient/GetApi"
import { IMAGE_URL } from '../Utils/urls';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate } from '../Utils/DateFormat';
import { red } from '../Utils/colors';

export default function Home() {

    const navigate = useNavigate();
    const { apiData } = useFetch("homeData");
    const [hoveredIndex, setHoveredIndex] = useState(null);
    console.log(apiData?.data);
    const PartyDetails = (obj) => {
        navigate("/party-details", { state: { id: obj.id } })
    }
    const pollingStationDetails = (obj) => {
        navigate("/pollingstation-details", { state: { id: obj.id } })
    }
    return (
        <div>
            <div class="wrapper">
                <Header />
                <div class="city_main_banner">
                    <div class="main-banner-slider">
                        <div>
                            <figure class="overlay" >
                                <img src="extra-images/main-banner.jpg" alt="" />
                                <div class="banner_text " style={{ height: "270px", width: "600px", color: "white" }} >
                                    <h5 style={{ color: "white" }}>The cloud-based Results collation and Analytics tool</h5>
                                    <h5 style={{ color: "white" }}>Inclusive. Accurate. Secure. Detailed</h5>
                                    <h2 style={{ color: "white" }} >Empowering Africa with Data-Driven Insights</h2>
                                    <Link style={{ color: "white", fontSize: "20px" }} to="results">Latest Result<i class="fa fa-angle-double-right"></i></Link>


                                </div>

                            </figure>
                        </div>
                    </div>
                </div>
            </div>

            <div class="city_about_wrap">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="city_about_fig">
                                <figure class="box">
                                    <div class="box-layer layer-1"></div>
                                    <div class="box-layer layer-2"></div>
                                    <div class="box-layer layer-3"></div>
                                    <img src="extra-images/about-fig.jpg" alt="" />
                                </figure>
                                <div class="city_about_video">
                                    <figure class="overlay">
                                        <img src="extra-images/about_video.jpg" alt="" />
                                        <a class="paly_btn hvr-pulse" data-rel="prettyPhoto" href="https://www.youtube.com/watch?v=SAaevusBnNI"><i class="fa icon-play-button"></i></a>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="city_about_list">

                                <div class="section_heading border">
                                    <span>Welcome to Results In</span>
                                    <h2>Africa</h2>
                                </div>

                                <div class="city_about_text">
                                    <h6>News & Result Updates</h6>
                                    <p>A live or regularly updated feed with headlines about key economic events, policy changes, or significant data releases in Africa.  </p>
                                    <p>Marketing, Ads, and other content</p>
                                    <Link to="/news" style={{ cursor: "pointer", fontSize: "20px", color: "red" }}>See More<i class="fa fa-angle-double-right"></i></Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="city_department_wrap overlay">
                <div class="bg_white">
                    <div class="container-fluid">

                        <div class="section_heading margin-bottom">
                            <span>Explore</span>
                            <h2>Political Parties</h2>
                        </div>

                        <div class="city-department-slider">
                            <div>
                                {
                                    apiData?.data?.parties?.map((party) => (
                                        <div class="width_control">
                                            <div class="city_department_fig">
                                                <figure class="box">
                                                    <div class="box-layer layer-1"></div>
                                                    <div class="box-layer layer-2"></div>
                                                    <div class="box-layer layer-3"></div>
                                                    {/* {
                                                        party.image ? <img style={{height:"120px",width:"90px"}} src={IMAGE_URL + party.image} alt="" /> : <img src="extra-images/department-fig.jpg" alt="" />
                                                    } */}


                                                </figure>
                                                <div class="city_department_text">
                                                    <h5>{party.name}</h5>
                                                    <div>
                                                        {
                                                            party.image ?
                                                                <img src={IMAGE_URL + party.image} style={{ height: "60px", width: "60px", borderRadius: "30px" }} class="rounded-circle" alt="" /> :
                                                                <img src="extra-images/department-fig.jpg" style={{ height: "60px", width: "60px", borderRadius: "30px" }} class="rounded-circle" alt="" />
                                                        }

                                                    </div>
                                                    <a style={{ cursor: "pointer" }} onClick={() => PartyDetails(party)}>See More<i class="fa fa-angle-double-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="city_office_wrap">
                <div class="bg_white bg_none">
                    <div class="container-fluid">
                        <div class="city_office_row">
                            <div class="city_triagle">
                                <span></span>
                            </div>
                            <div class="center_text">
                                <div class="city_office_list">
                                    <div class="city_office_text">
                                        <h6>View</h6>
                                        <h3>All Results</h3>
                                        <Link to="results">See More<i class="fa fa-angle-double-right"></i></Link>
                                    </div>
                                    <div class="city_office_logo">
                                        <a href="#"><img src="images/office-logo.png" alt="" /></a>
                                    </div>
                                    <div class="city_office_text pull_right">
                                        <h6>View</h6>
                                        <h3>All Candidates</h3>
                                        <Link to="/all-candidates">See More<i class="fa fa-angle-double-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="city_triagle text-right">
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="city_event_wrap">
                <div class="bg_white width">
                    <div class="container-fluid">

                        <div class="heding_full">
                            <div class="section_heading">

                                <h2>Active Pollings</h2>
                            </div>
                        </div>
                        <div className="row">
                            {apiData?.data?.pollingStations?.map((poll, index) => (
                                <div
                                    key={poll.id} // Ensure to use a unique key
                                    onClick={() => pollingStationDetails(poll)}
                                    className="col-md-6 col-sm-6"
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="city_event_fig">
                                        <figure className="box">
                                            <div className="box-layer layer-1"></div>
                                            <div className="box-layer layer-2"></div>
                                            <div className="box-layer layer-3"></div>
                                        </figure>
                                        <div
                                            style={{
                                                backgroundColor: hoveredIndex === index ? red : 'transparent',
                                                transition: 'background-color 0.3s ease', // Smooth transition
                                                padding: '10px', // Optional padding for better appearance
                                            }}
                                            onMouseEnter={() => setHoveredIndex(index)}
                                            onMouseLeave={() => setHoveredIndex(null)}
                                            className="city_event_text"
                                        >
                                            <div className="city_event_history">
                                                <div className="event_date" style={{ width: "100px" }}>
                                                    <strong>{formatDate(poll?.createdAt)}</strong>
                                                </div>
                                                <div className="city_date_text">
                                                    <h4>{poll?.name}</h4>
                                                    <h5>Code: {poll?.code}</h5>
                                                    <p>No. of Candidates: {poll?.candidatepollingstations?.length}</p>
                                                    <p>Total Voters: {poll?.totalVoters}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
