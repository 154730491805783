import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function 
() {
  return (
    <div>
        <Header/>
        <div class="wrapper">
        <div class="sab_banner overlay">
				<div class="container">
					<div class="sab_banner_text">
						<h2>About Us</h2>
						<ul class="breadcrumb">
						  <li class="breadcrumb-item"><a href="/">Home</a></li>
						  <li class="breadcrumb-item active">About Us</li>
						</ul>
					</div>
				</div>
			</div>
            <div class="city_blog2_wrap">
				<div class="container">
					<div class="city_project_img">
						<figure class="box">
							<div class="box-layer layer-1"></div>
							<div class="box-layer layer-2"></div>
							<div class="box-layer layer-3"></div>
							<img src="extra-images/project-detail.jpg" alt=""/>
						</figure>	
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="city_project_detail">
								<div class="city_project_description">
									<h4 class="project_title">Who We Are</h4>
									<p>Results in Africa is a leading data-driven platform dedicated to providing timely and reliable insights into key sectors of African economies. Our mission is to empower individuals, businesses, governments, and organizations with accurate, transparent data that drives informed decisions across the continent.</p>
								</div>
								
								<div class="city_project_description">
									<h4 class="project_title">What We Do</h4>
									<p>Our platform covers a wide range of data including election results, economic performance indicators, trade statistics, employment figures, and survey results. By providing this data in an accessible format, we bridge the gap between raw data and actionable insights.</p>
									
								</div>
								<div class="city_project_description">
									<h4 class="project_title">Our Mission</h4>
									<p>Our mission is to foster transparency, accountability, and growth in Africa by offering reliable and easy-to-understand data across all sectors of the economy. We believe that data is a powerful tool for decision making, shaping policies, driving business strategies, and fostering civic engagement.</p>
								</div>
								<div class="city_project_description">
									<h4 class="project_title">Why Results Matter</h4>
									<p>Whether you're a policy-maker analyzing economic trends, a business leader tracking market performance, or a citizen staying informed about elections, our data provides the insights needed to make informed decisions. We are committed to ensuring that every African has access to trustworthy data that reflects the true state of their nation’s progress.</p>
								</div>
								<div class="city_project_description">
									<h4 class="project_title">Our Values</h4>
									<ul>
                                        <li><strong>Accuracy:</strong> Providing reliable, up-to-date data from verified sources.</li>
                                        <li><strong>Transparency:</strong> Ensuring openness in how we gather, present, and interpret data.</li>
                                        <li><strong>Inclusivity:</strong> Inclusivity: Covering all regions of Africa and making data available to all.</li>
                                        <li><strong>Empowerment:</strong>Empowerment: Enabling users to make decisions based on credible data.</li>
                                    </ul>
								</div>
                                <div class="city_project_description">
									<h4 class="project_title">How We Source Data</h4>
									<p>Our data is sourced from trusted national and international agencies, actors in the electoral process, research organizations, and independent analysts. By aggregating data from multiple verified sources, we ensure that the information presented on our platform is both reliable and comprehensive.</p>
								</div>
                                <div class="city_project_description">
									<h4 class="project_title">Call to Action</h4>
									<p>Join us in shaping Africa’s future by staying informed with the latest data. Explore our platform, download reports, and use our tools to better understand the dynamics that shapes African economies.</p>
								</div>
                                <div class="city_project_description">
									<h4 class="project_title">Future Vision</h4>
									<p>As we continue to grow, our goal is to expand our data coverage and offer even more tools for analyzing and visualizing results. We envision a future where data-driven decision-making empowers every sector of the African society.</p>
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
			</div>
             </div>
        <Footer/>
    </div>
  )
}
