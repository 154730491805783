
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from "./components/Home";
import PartyDetails from "./components/PartyDetails";
import CandidateDetails from "./components/CandidateDetails";
import AllCandidates from "./components/AllCandidates";
import PollingStationDetails from "./components/PollingStationDetails";
import Result from "./components/Result";
import ContactUs from "./components/ContactUs";
import News from "./components/News";
import AboutUs from "./components/AboutUs";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          {/* Route Definitions */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/party-details" element={<PartyDetails />} />
            <Route path="/candidate-details" element={<CandidateDetails />} />
            <Route path="/all-candidates" element={<AllCandidates />} />
            <Route path="/pollingstation-details" element={<PollingStationDetails />} />
            <Route path="/results" element={<Result />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/news" element={<News />} />
            <Route path="/about-us" element={<AboutUs />} />
           
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
